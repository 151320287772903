<template>
  <div>
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Available Merchants
        </h4>
      </div>
    </div>
    <section style="background-color: #eee;">
      <div class="container py-5">
        <div v-if="loading" class="row py-5 justify-content-center">
          <fade-loader :loading="loading" :color="color"></fade-loader>
        </div>
        <div
          v-else-if="response.item1 && response.item1.length > 0"
          class="row justify-content-center mb-3"
        >
          <div
            v-for="item in response.item1"
            :key="item.id"
            class="col-md-12 col-xl-10"
          >
            <div class="card shadow-0 border rounded-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                    <div
                      class="bg-image hover-zoom ripple rounded ripple-surface"
                    >
                      <img
                        :src="
                          `${ImagebaseUrl}/oonpayhealthhubsetup/${imgurl}`
                        "
                        alt=""
                        class="w-100"
                      />
                      <a href="#!">
                        <div class="hover-overlay">
                          <div
                            class="mask"
                            style="background-color: rgba(253, 253, 253, 0.15);"
                          ></div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-6">
                    <h5>{{ item.merchantBusinessName }}</h5>
                    <div class="">
                      <h6>{{ item.fullName }}</h6>
                      <h6>{{ item.merchantPhoneNumber }}</h6>
                      <p>{{ item.merchantStreetAddress }}</p>
                      <p>
                        {{ Math.round(item.distanceAway / 1000, 2) }} KM Away
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start"
                  >
                    <div class="d-flex flex-row align-items-center mb-1">
                      <h4 class="mb-1 me-1">
                        {{ currency }} {{ Math.round(item.cost * 10) / 10 }}
                      </h4>
                      <!-- <span class="text-danger"><s>$20.99</s></span> -->
                    </div>
                    <h6 class="text-success">
                      Expires {{ item.expirationQuantity }}
                      {{ item.expirationQuantityUnit }} After Purchase
                    </h6>
                    <!-- <span>In Stock: {{ item.availableStock }} </span> -->

                    <div class="d-flex flex-column mt-4">
                      <button
                        @click="PackageSpec(item.guid)"
                        class="btn btn-primary btn-sm"
                        type="button"
                      >
                        Details
                      </button>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row justify-content-center mb-3">
          <div class="col-md-12 col-xl-10">
            <div class="card shadow-0 border rounded-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 text-center mb-4 mb-lg-0">
                    <p class="text-xl">No Merchant Found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from "../../config";
export default {
  components: { FadeLoader },
  data() {
    return {
      ImagebaseUrl,
      response: [],
      imgurl: localStorage.getItem("imgurl"),
      loading: false,
      color: "#0b4369",
      currency: JSON.parse(localStorage.getItem("account_info")).countryCurrency,
    };
  },
  mounted() {
    this.countryISOCode = localStorage.getItem("clientCountryISO");
    const payload = {
      latitude: localStorage.getItem("latitude"),
      longitude: localStorage.getItem("longitude"),
      packageCountryId: localStorage.getItem("countryId"),
      clientCountryISO: localStorage.getItem("clientCountryISO"),
      packageSubCategoryId: localStorage.getItem("packageSubCategoryId"),
      pageNumber: 1
    };
    this.loading = true;
    this.$store.dispatch("healthhubclient/SearchPack", payload).then((res) => {
      if (res.status == 200) {
        this.response = res.data;
        this.loading = false;
      }
    });
  },
  methods: {
    PackageSpec(id) {
      localStorage.setItem("packageGUID", id);
      this.$router.push("/healthhub-checkout");
    }
  }
};
</script>

<style lang="scss" scoped>
.oonpay-bg {
  background: #0c4369;
}
</style>
